const routes = [
	{
		path: '/',
		name: 'Dashboard',
		layout: 'dashboard',
	},
	{
		path: '/connect',
		name: 'Connect',
		layout: 'connect',
	},
	{
		path: '/direct',
		name: 'Direct',
		layout: 'direct',
	},
];

export default routes;
