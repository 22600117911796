import 'es6-shim';
import React from 'react';

import { ConfigProvider } from 'antd';
import PropTypes from 'prop-types';
import HttpsRedirect from 'react-https-redirect';
import { Switch, Route } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import routes from 'config/router.config';
import { apiDocsTheme } from 'config/theme.config';

import Loader from 'components/Loader';

const Layout = Loader(() => import('layouts/AppLayout'));

App.propTypes = {
	container: PropTypes.node,
	layout: PropTypes.node,
	containerProps: PropTypes.object,
};

export default function App() {
	return (
		<HttpsRedirect>
			<ConfigProvider prefixCls={'cuboh'} theme={apiDocsTheme}>
				<LastLocationProvider>
					<Switch>
						{routes.map((route) => {
							const { container: Component, containerProps } = route;
							return route.routes ? (
								route.routes.map((subRoute) => {
									const { container: Component, containerProps } = subRoute;
									return (
										<Route
											exact
											key={subRoute.key}
											path={subRoute.path}
											render={() => (
												<Layout {...route}>
													<Component {...containerProps} />
												</Layout>
											)}
										/>
									);
								})
							) : (
								<Route
									exact
									key={route.name}
									path={route.path}
									layout={route.layout}
									render={() => (
										<Layout {...route}>
											<Component {...containerProps} />
										</Layout>
									)}
								/>
							);
						})}
					</Switch>
				</LastLocationProvider>
			</ConfigProvider>
		</HttpsRedirect>
	);
}
