/* eslint-disable react/display-name */
import React, { Suspense, lazy } from 'react';

import { Card, Col, Skeleton, Spin } from 'antd';

export default function Loader(dynamicComponent, customLoader) {
	const LazyComponent = lazy(dynamicComponent);
	return ({ ...props }) => (
		<Suspense
			fallback={
				customLoader !== undefined ? (
					customLoader
				) : (
					<Spin
						style={{
							position: 'absolute',
							top: '45%',
							left: '50%',
						}}
						size="large"
					/>
				)
			}
		>
			<LazyComponent {...props} />
		</Suspense>
	);
}

export function LoaderQuiet(dynamicComponent) {
	return Loader(dynamicComponent, null);
}
/* eslint-disable react/prop-types */
export function CardLoader({ span }) {
	return (
		<Col span={span}>
			<Card loading={true}></Card>
		</Col>
	);
}

export function SkeletonLoader() {
	return <Skeleton active></Skeleton>;
}
